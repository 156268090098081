.about-page-title {
  margin-bottom: 20vh;
}

.about-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 90vh;
  position: relative;
  font-size: 2em;

}

.about-1{
  margin-right: 30px;
  margin-top: 50px;
}

.about-2{
  margin-left: 30px;
}


@media screen and (max-width: 850px) {
.about-main{
  font-size: 1.8em;
}
}

@media screen and (max-height: 850px) {
.about-main{
  font-size: 1.5em;
}
@media screen and (max-width: 700px) {
  .about-main{
    font-size: 1em;
  }
  }
}

@media screen and (max-width: 450px) {
  .about-2 {
    padding-left: 8px;
  }

  .about-main {
    font-size: 1.3em;
  }
}