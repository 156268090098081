.media-main {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.iframe-ctr {
    margin: 0;
    padding: 0;
    min-width: 60vw;
    min-height: 40vw;
    margin-bottom: 20px;
    position: relative;
}

iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    min-width: 350px;
    left: 0;
    top: 0;
}



.frame1 {
    margin: 0 auto 12px 1vw;
}

.frame2 {
    margin: 0 1vw 12px auto;
}

.frame3 {
    margin: 0 auto;
}

.media-main * {
    margin-top: 7vh;
    /* border: 1px solid red; */
}

@media screen and (max-width: 650px) {
    .iframe-ctr {
        margin: 0;
        max-width: 90vh;
    }

    .frame1 {
        margin: 0;
        margin-bottom: 12px;
    }

    .frame2 {
        margin: 0;
        margin-bottom: 12px;
    }

    .frame3 {
        margin: 0;
    }

}