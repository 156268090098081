@font-face {
  font-family: 'NNN';
  src: url('fonts/NNNouvelleGroteskTrial-Fat.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NNNdiagonal';
  src: url('fonts/NNNouvelleGroteskTrial-FatDiagonal.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  text-decoration: none;
}


a {
  color: black;
}


.App {
  text-align: center;
  background-color: #149600;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "NNN";
  color: rgb(0, 0, 0);
  position: relative;
  overflow-x: hidden;
}

html,
body {
  height: auto;
  overflow: auto;
}

.landing-main,
.media-main {
  min-height: 70vh;
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn,
input {
  font-family: "NNN";
  font-size: 2em;
}

textarea {
  font-family: "NNN";
  font-size: 1em;
}


.center-div {
  margin: 0 auto;
}

.App-link {
  color: #61dafb;
}

h2 {
  margin: 0;
}

.text-right {
  text-align: right;
}


.text-left {
  text-align: left;
}

.text-ctr {
  line-height: 1.15em;
}

.rotate-15 {
  rotate: -15deg;
  width: fit-content;
}

.about-page-top {
  margin-left: 2.5vw;
}

.rotate-5 {
  rotate: -5deg;
  width: fit-content;
}

.rotate7 {
  rotate: 4.5deg;
  width: fit-content;
}

.rotate12 {
  rotate: 12deg;
  width: fit-content;
}



.diagonal {
  font-family: "NNNDiagonal";
}


.desktop-only {
  display: inline
}


#donate-btn{
  margin-top: 20vh;
  min-height: 30vw;
  width: 50vw;
}

@media screen and (min-width: 450px) {
  .rotate-15,
  .rotate7 {
    margin-bottom: 32px;
  }
}


@media screen and (max-width: 550px) { 
  .desktop-only {
    display: none;
  }

  .App{
      overflow-y: auto;
  }

}
@media screen and (max-height: 720px) {
  .App{
      overflow-y: auto;
  }
  }
  