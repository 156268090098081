.landing-main {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 98vw;
    padding: 0 4px;
    position: relative;
    height: 80vh;
}

.landing-main * {
    /* border: 1px solid red; */
}

.landing-main>.rotate7 {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    height: 70%;
    justify-content: end;
}

.landing-div-1 {
    width: fit-content;
    align-self: end;
    margin-bottom: 16px;
}

.landing-div-2 {
    width: fit-content;
    align-self: end;
    text-align: right;
}

.landing-the {
    margin-left: -.42rem;
}

@media screen and (max-width: 650px) {
    .landing-the {
        margin-left: -4px;
    }

    .landing-main * {
        font-size: 1em;
    }

}

@media screen and (max-width: 450px) {
    .landing-the {
        margin-left: -3px;
    }

    .landing-main * {}

}