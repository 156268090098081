.btn {
    color: white;
    background-color: black;
    border: 1px solid gray;
    padding: 12px;
    margin-top: 8px;
}


.contact-form,
.msg-success {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: black;
    width: 60vw;
    margin: 20vh auto;
    padding: 2vmin 8vmin;
    min-height: 400px;
    min-width: 350px;
    color: white;
}

.msg-success {
    line-height: .8em;
    text-align: left;

}

.form-field {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: fit-content;
    margin: 0 auto;
}


textarea,
.text-input {
    width: 40vw;
    min-height: 50px;
    min-width: 300px;
}


textarea {
    min-height: 100px;
}


@media screen and (max-height: 525px) {
    .contact-form,
    .msg-success{
        min-height: 80vh;
    }
}

@media screen and (max-width: 750px) {
}