.artists-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 75vh;
    font-size: 1.5em;
    line-height: 1.15em;
    margin-top: 7vh;
}


.artists-page-title {
    text-align: left !important;
}

.artists-1 {
    margin-top: 80px;
    margin-left: auto;
    margin-right: 16px;
    text-align: right !important;
}

.artists-2 {
    margin-left: 36px;
    margin-top: -24px;
    margin-bottom: 44px;
}

.artists-3 {
    margin-right: 20px;
    margin-bottom: -44px;
}

.artists-4 {
    margin-left: 8px;
}

.artists-5 {
    margin-left: 12px;
    rotate: 11deg !important;
    margin-top: 72px;
}

@media screen and (max-height: 720px) {
.artists-main{
min-height: 110vh;
}
.App{
    overflow-y: auto;
}

}

@media screen and (max-width: 1080px) {
    .artists-main {
        font-size: 1.3em;
    }
}

@media screen and (max-width: 920px) {

    .artists-main {
        font-size: 1.1em;
    }

    @media screen and (max-height: 525px) {
        .artists-main {
            font-size: 1em;
        }

}
}

@media screen and (max-width: 720px) {
    .artists-main {
        font-size: 1em;
    }
}



@media screen and (max-width: 600px) {
    .artists-main {
        min-height: 72vh;
        max-height: 72vh;
    }

    .artists-1 {
        margin-top: -70px;
    }

    .artists-2 {
        margin-left: 36px;
        margin-top: 0;
        margin-bottom: -80px;
    }

    .artists-3 {
        margin-right: 20px;
    }

    .artists-4 {
        margin-left: 8px;
    }

    .artists-5 {
        margin-left: 12px;
        rotate: 11deg !important;
        margin-top: -24px;
    }


    .artists-page-title {
        text-align: right !important;
    }

}

@media screen and (max-height: 525px) {
    .artists-main {
        font-size: .8em;
    }

}