.happlab-1 {
    top: 10%;
    left: 30vw;
    width: fit-content;
}

.happlab-2 {
    left: 4vw;
    width: fit-content;
    rotate: -6deg;
    top: 37%;
}

.happlab-3 {
    right: 2vw;
    width: fit-content;
    bottom: 12%;
    text-align: right;
}

.happlab-4 {
    right: 30vw;
    width: fit-content;
    rotate: 8deg;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 90%;
}

.happlab-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    position: relative;
    align-items: center;
    font-size: 1.3em;
    line-height: 1.15em;
}

.happlab-page-title {
    position: absolute;
    left: 5vw;
    top: 4%;
    rotate: -6deg;
    font-size: 1.3em;
}

.happlab-1,
.happlab-2,
.happlab-3,
.happlab-4,
.happlab-5 {
    position: absolute;
}

@media screen and (max-height: 800px) {
    .happlab-main {
        min-height: 800px;
    }

}

@media screen and (max-width: 1000px) {
    .happlab-1 {
        left: -5vw;
        min-width: 100vw;
        top: 6%;
    }

    .happlab-2 {
        left: 5vw;
        min-width: 100vw;
        top: 34%;
    }



    .happlab-3 {
        top: 58%;
        min-width: 100vw;
    }

    .happlab-4 {
        top: 90%;
    }

    @media screen and (max-height: 1000px) {
        .happlab-4 {
            top: 100%;
        }
    }
}

@media screen and (max-width: 800px) {
.happlab-main{
    font-size: 1em;
}
    .happlab-1 {
        left: -5vw;
        min-width: 100vw;
        top: 8%;
    }

    .happlab-2 {
        left: 5vw;
        min-width: 100vw;
        top: 33%;
    }



    .happlab-3 {
        top: 54%;
        min-width: 100vw;
    }

    .happlab-4 {
        text-align: left;
        top: 94%;
        min-width: 100vw;
        left: 25vw;
    }
}

@media screen and (max-width: 600px) {
    .happlab-main {
        min-height: 800px;
        font-size: .8em;
    }

    .happlab-1 {
        left: -5vw;
        min-width: 100vw;
        top: 8%;
    }

    .happlab-2 {
        left: 5vw;
        min-width: 100vw;
        top: 33%;
        rotate: -2deg;
    }


    .happlab-3 {
        text-align: left;
        top: 54%;
        min-width: 100vw;
        left: 2vw;
    }

    .happlab-4 {
        text-align: left;
        top: 82%;
        min-width: 100vw;
        left: 5vw;
        rotate: 4deg;
    }

    @media screen and (max-height: 850px) {
        @media screen and (min-height: 750px) {
            .happlab-4 {
                top: 90%;
            }
        }
    }

}