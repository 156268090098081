header {
    height: 7vh;
    min-height: 20px;
    z-index: 3;
    position: absolute;
    right: 0;
}

.menu {
    margin: 0 1vw 0 auto;
    width: fit-content;
    cursor: pointer;
    font-size: 2.5em;

}

.menu:hover {
    background-color: black;
    color: white;
    transition: 200ms;
}

.menu-content {
    position: absolute;
    background: rgba(0, 0, 0, 1);
    width: fit-content;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    right: 1vw;
    padding: 4px;
    z-index: 1;
}


.menu-link-text {
    color: white;
    text-decoration: none;
    text-align: right;
    line-height: 1rem;
    margin: 4% 0;
    padding: 0;
    font-size: 1.5em;
}


.menu-link-text:hover {
    box-shadow: 0 -1px 0px 6px white;
}

/* .menu-content:hover .menu-link-text {
    color: gray;
} */

.menu-link-text:visited {
    color: white;
    text-decoration: none;
}

.menu-link-text:hover {
    color: black;
    background-color: white;
    text-decoration: none;
}

.Link:visited {
    text-decoration: none;
}

@media screen and (max-width: 550px) {
    .menu-link-text {
        font-size: 32px;
        padding-top: 8px;
    }
}

@media screen and (max-width: 450px) {
    .menu-link-text {
        margin: 0;

    }
}