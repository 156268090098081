.upcoming-top {
    margin-left: 16%;
    margin-bottom: 18px;
    margin-top: 8px;
    width: fit-content;
}

.upcoming-page-title {
    width: 100%;
    margin-top: 30px;
    margin-left: 10px;
  }
  
  .upcoming-content {
    margin-top: 10px;
    max-width: 90vw;
  }

.upcoming-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 90vh;
    position: relative;
    align-items: center;
    line-height: 1.15em;
    font-size: 1.5em;

}


.upcoming-main-img {
    position: absolute;
    max-width: 100vw;
    max-height: 100%;

}


.upcoming-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
}


@media screen and (max-width: 650px) {
    .upcoming-top {
        margin-left: 64px;
    }
}

@media screen and (max-width: 600px) {
    .upcoming-top {
        margin-bottom: 60px;
        margin-left: 0;
    }

    .upcoming-main {
        min-height: 80vh;
    }


}